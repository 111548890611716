<template>
  <div class="report-container">
    <div class="search-box">
      <h2 class="search-title border-bottom">按条件搜索</h2>
      <Row class="search-row">
        <Col span="4">
          <span class="sub-label">省份：</span>
          <div class="sub-content">
            <Select clearable
                    v-model="searchData.pid"
                    placeholder="请选择..."
                    @on-change="changePro">
              <Option :value="v.id"
                      v-for="v in proList"
                      :key="v.id">{{v.name}}</Option>
            </Select>
          </div>
        </Col>
        <Col span="4" offset="1">
          <span class="sub-label">城市：</span>
          <div class="sub-content">
            <Select clearable
                    v-model="searchData.cid"
                    placeholder="请选择..."
                    @on-change="changeCity">
              <Option :value="v.id"
                      v-for="v in cityList"
                      :key="v.id">{{v.name}}</Option>
            </Select>
          </div>
        </Col>
        <Col span="4" offset="1">
          <span class="sub-label">门店：</span>
          <div class="sub-content">
            <Select clearable
                    v-model="searchData.sid"
                    placeholder="请选择...">
              <Option :value="v.id"
                      v-for="v in storeList"
                      :key="v.id">{{v.name}}</Option>
            </Select>
          </div>
        </Col>
        <Col span="4" offset="1">
          <span class="sub-label">姓名：</span>
          <div class="sub-content">
            <Input v-model="searchData.name" placeholder="输入顾客名称或服务员工"></Input>
          </div>
        </Col>
        <Col span="4" offset="1">
          <Button type="primary" @click="handleSearchMember">搜索</Button>
        </Col>
      </Row>
    </div>
    <div class="content-box mb20">
      <h2 class="content-title border-bottom">
        <span>会员列表</span>
      </h2>
      <div class="content-body">
        <Table
          height="220"
          highlight-row
          :columns="memberColumns"
          :data="memberList"
          @on-current-change="handleMemberSelected"
          ref="table"></Table>
      </div>
    </div>
    <div class="content-box">
      <h2 class="content-title border-bottom">
        <span>检测报告</span>
        <Button type="primary" @click="addReport">上传报告</Button>
      </h2>
      <div class="content-body">
        <ul class="header">
          <li :class="['header-item', index == active ? 'active': '']" 
            v-for="(item, index) in tabs" 
            :key="item.type" 
            @click="onClickTab(index)">{{ item.name }}</li>
        </ul>
        <Form :model="reportData" inline class="form" v-if="tabs.length > 0">
          <FormItem label="时间：" class="inline-form-item">
            <DatePicker
              type="daterange"
              placeholder="选择时间"
              style="width: 200px"
              @on-change="handleDateChange"></DatePicker>
          </FormItem>
          <FormItem>
            <Select
              v-model="reportData.month_flag"
              style="width:200px"
              @on-change="handleSearchReport">
              <Option :value="0">默认</Option>
              <Option :value="1">最近一个月</Option>
            </Select>
          </FormItem>
          <FormItem
            label="报告名称："
            v-if="active > 0 && tabs[active].child.length > 0"
            class="inline-form-item">
            <Select
              v-model="reportData.pic_type"
              style="width:200px"
              @on-change="handleSearchReport">
              <Option :value="item.id" v-for="item in tabs[active].child" :key="item.id">
                {{ item.name }}
              </Option>
            </Select>
          </FormItem>
        </Form>
        <Table
          :columns="reportColumns"
          :data="reportList"
          ref="table"></Table>
        <div>
          <span class="records">共 {{totalReports}} 条记录</span>
        </div>
        <div class="page-nations-wrapper">
          <div class="page-nations">
            <Page
              :total="totalReports"
              :current="reportData.page"
              :page-size="reportData.size"
              @on-change="changeDataPage"></Page>
          </div>
        </div>
      </div>
    </div>
    <Modal
      v-model="showModal"
      title="上传报告"
      class-name="vertical-center-modal"
      @on-ok="handleSubmit">
      <Form :model="formData" :label-width="100">
        <Form-item label="报告类型">
          <Select v-model="formData.type" @on-change="onTypeChange">
            <Option
              :value="type.type"
              v-for="type in types" 
              :key="type.type">{{ type.name }}</Option>
          </Select>
        </Form-item>
        <Form-item label="报告名称" v-if="formData.type && types[formData.type - 1].child.length > 0">
          <Select v-model="formData.bgzl">
            <Option
              :value="sub.id"
              v-for="sub in types[formData.type - 1].child"
              :key="sub.id">{{ sub.name }}</Option>
          </Select>
        </Form-item>
        <Form-item label="文件">
          <Upload action="/api/check/photo/upload"
                  name="photo"
                  ref="upload"
                  :max-size="1024 * 30"
                  :data="{check: 1}"
                  :format="['png','jpg','jpeg','pdf']"
                  :on-format-error="handleFormatError"
                  :on-success="handleUploadSuccess"
                  :on-exceeded-size="handleMaxSize"
                  :on-remove="handleRemove"
                  :before-upload="handleBeforeUpload">
            <Button icon="ios-cloud-upload-outline">上传</Button>
          </Upload>
          <span style="font-size:12px;color:red;">* 仅能上传文件大小为≤30MB，PDF、PNG、JPG格式文件</span>
        </Form-item>
        <Form-item label="报告描述">
          <Input v-model="formData.bgms" placeholder="请输入..."></Input>
        </Form-item>
        <Form-item label="专家解读">
          <Input v-model="formData.zxnr" type="textarea" placeholder="请输入..."></Input>
        </Form-item>
      </Form>
    </Modal>
  </div>
</template>

<script>
import qualityService from '@/services/qualityService';
import memberService from '@/services/memberService';
import defaultAvatar from '@/assets/img/bg_avatar.png';

export default {
	data() {
		return {
			searchData: {
				page: 1,
				size: 1000,
				pid: '',
				cid: '',
				sid: '',
				name: '',
				zj_check_date: 1,
			},
			reportData: {
				page: 1,
				size: 10,
				member_id: '',
				month_flag: 0,
				type: null,
				pic_type: null,
			},
			storeList: [],
			proList: [],
			cityList: [],
			reportType: [],
			reportName: [],
			totalReports: 0,
			selectedDataItem: [],
			memberList: [],
			memberColumns: [
				{ title: '姓名', key: 'true_name', align: 'center' },
				{
					title: '头像',
					key: 'photo',
					align: 'center',
					render(h, params) {
						if (params.row.photo) {
							return h('div', [
								h('img', {
									class: 'img-avatar',
									attrs: {
										src: params.row.photo,
									},
								}),
							]);
						} else {
							return h('div', [
								h('img', {
									class: 'img-avatar',
									attrs: {
										src: defaultAvatar,
									},
								}),
							]);
						}
					},
				},
				{ title: '顾客类型', key: 'new_member_type', align: 'center' },
				{ title: '联系电话', key: 'phone', align: 'center' },
				{ title: '所属分院', key: 'branch', align: 'center' },
				{ title: '最近检测日期', key: 'zjjcrq', align: 'center' },
				{ title: '服务员工', key: 'staff', align: 'center' },
				{
					title: '操作',
					key: 'action',
					width: 150,
					align: 'center',
					render: (h, params) => {
						return h('a', {
							class: 'icon_eye',
							attrs: {
								href: '#/base/member/detail/' + params.row.id,
								target: '_blank',
							},
							style: {
								marginRight: 0,
							},
						});
					},
				},
			],
			reportList: [],
			reportColumns: [
				{
					title: '检测日期',
					align: 'center',
					render(h, params) {
						return h('span', params.row.cjrq.substring(0, 10));
					},
				},
				{
					title: '上传日期',
					align: 'center',
					render(h, params) {
						return h('span', params.row.scrq.substring(0, 10));
					},
				},
				{ title: '报告类型', key: 'bglx', align: 'center' },
				{ title: '报告类别', key: 'bglb', align: 'center' },
				{ title: '报告名称', key: 'bgmc', align: 'center' },
				{ title: '顾客名称', key: 'gkmc', align: 'center' },
				{ title: '报告描述', key: 'bgms', align: 'center' },
				{ title: '服务员工', key: 'jcs', align: 'center' },
				{ title: '所属分院', key: 'md', align: 'center' },
				{
					align: 'center',
					title: '报告预览',
					render: (h, params) => {
						const reportLink = params.row.url;
						let placeholderPath = reportLink;
						if (reportLink.match(/\.pdf$/)) {
							placeholderPath = new URL('/src/assets/img/placeholder-pdf.jpg', import.meta.url)
								.href;
						} else if (!reportLink.match(/\.(png|jpe?g|gif|svg)$/)) {
							placeholderPath = new URL('/src/assets/img/placeholder.png', import.meta.url).href;
						}
						return h(
							'a',
							{
								attrs: {
									href: reportLink,
									target: '_blank',
								},
							},
							[
								h('img', {
									attrs: {
										src: placeholderPath,
									},
									style: {
										padding: '10px 0',
										width: '100px',
										height: '100px',
										'object-fit': 'contain',
									},
								}),
							],
						);
					},
				},
				{
					align: 'center',
					title: '操作',
					width: 140,
					render: (h, params) => {
						return h('div', [
							h('a', {
								class: 'icon_edit',
								style: {
									margin: '0 5px',
								},
								on: {
									click: () => {
										this.$router.push({
											name: 'memberReportAdd',
											params: { id: params.row.id, member_id: params.row.member_id },
										});
									},
								},
							}),
							h('a', {
								class: 'icon_eye',
								style: {
									margin: '0 5px',
								},
								on: {
									click: () => {
										this.$router.push({
											name: 'memberReportDetail',
											params: { id: params.row.id, member_id: params.row.member_id },
										});
									},
								},
							}),
							h('Icon', {
								attrs: {
									type: 'md-arrow-round-down',
									size: 22,
									color: '#fdad00',
								},
								style: {
									margin: '0 5px',
									cursor: 'pointer',
								},
								on: {
									click: () => {
										const a = document.createElement('a');
										a.href = params.row.url;
										a.style.display = 'none';
										a.setAttribute('download', params.row.bgmc);
										document.body.appendChild(a);
										a.click();
										document.body.removeChild(a);
									},
								},
							}),
						]);
					},
				},
			],
			tabs: [],
			types: [],
			active: 0,
			showModal: false,
			formData: {
				type: '',
				bgzl: '',
				bgms: '',
				zxnr: '',
				photo_id: '',
				member_id: '',
			},
			uploadList: [],
		};
	},
	created() {
		let list = JSON.parse(localStorage.getItem('userInfo'));
		qualityService.getQualitySelect().then((data) => {
			this.power = list.user_info.power;
			if (this.power.indexOf('会员管理--查看所属店面会员信息权限') === -1) {
				this.branchShow = false;
			} else {
				if (
					this.power.indexOf('会员管理--查看全国会员信息权限') === -1 &&
					this.power.indexOf('会员管理--查看所属区域会员信息权限') === -1
				) {
					this.branchShow = true;
					this.branch_id = list.user_info.branch;
					this.searchData.sid = this.branch_id[2];
					this.searchData.cid = this.branch_id[1];
					this.searchData.pid = this.branch_id[0];
					this.proName = list.user_info.pro_name;
					this.cityName = list.user_info.city_name;
					this.sName = list.user_info.branch_name;
				}
			}
			this.proList = data.branch.pro;
			this.city = data.branch.city;
			this.store = data.branch.store;
		});
	},
	mounted() {
		this.uploadList = this.$refs.upload.fileList;
	},
	methods: {
		changePro() {
			this.cityList = this.city[this.searchData.pid];
			this.searchData.cid = '';
		},
		changeCity() {
			this.storeList = this.store[this.searchData.cid];
			this.searchData.sid = '';
		},
		changeDataPage(page) {
			this.reportData.page = page;
			this.handleSearchReport();
		},
		handleSearchMember() {
			if (this.searchData.sid) {
				memberService.getMemberQuery(this.searchData).then((data) => {
					this.memberList = data.list;
				});
			} else {
				this.$Message.error('请选择门店');
			}
		},
		handleSearchReport() {
			memberService.getReportList(this.reportData).then((data) => {
				this.reportList = data.list;
				this.totalReports = data.row_size;
			});
		},
		getReportType() {
			memberService.getReportType({ member_id: this.reportData.member_id }).then((data) => {
				this.tabs = [{ type: null, name: '全部' }].concat(data);
				this.types = data;
			});
		},
		handleMemberSelected(val) {
			this.reportData.member_id = val.id;
			this.formData.member_id = val.id;
			this.handleSearchReport();
			this.getReportType();
		},
		onClickTab(index) {
			this.active = index;
			this.reportData.type = this.tabs[index].type;
			this.reportData.pic_type = null;
			this.handleSearchReport();
		},
		handleDateChange(val) {
			if (val.every((item) => item)) {
				this.reportData.start_time = val[0];
				this.reportData.end_time = val[1];
			} else {
				this.reportData.start_time = null;
				this.reportData.end_time = null;
			}
			this.handleSearchReport();
		},
		addReport() {
			if (this.formData.member_id) {
				this.showModal = true;
			} else {
				this.$Message.error('请选择会员');
			}
		},
		onTypeChange() {
			this.formData.bgzl = '';
		},
		handleUploadSuccess(response) {
			if (response.c === 0) {
				this.formData.photo_id = response.data.id;
				this.formData.check_date = response.data.check_date;
			} else {
				const uploadRef = this.$refs.upload;
				this.$Message.error(response.m);
				uploadRef.handleRemove(uploadRef.fileList[0]);
			}
		},
		handleMaxSize(file) {
			this.$Message.warning('文件 ' + file.name + ' 太大，不能超过 30 M。');
		},
		handleFormatError(file) {
			this.$Message.warning(
				'文件 ' + file.name + ' 格式不正确，请上传 png、jpg、jpeg 或 pdf 格式的文件。',
			);
		},
		handleBeforeUpload() {
			const check = this.uploadList.length < 1;
			if (!check) {
				this.$Message.warning('最多只能上传 1 个文件。');
			}
			return check;
		},
		handleRemove() {
			this.formData.photo_id = '';
		},
		handleSubmit() {
			memberService
				.addReport(this.formData)
				.then(() => {
					this.$Message.success('提交成功');
					this.handleSearchReport();
				})
				.catch(() => {
					this.$Message.warning('提交失败，请重试');
				});
		},
	},
};
</script>

<style lang="less" scoped>
.search-box {
  margin-top: 15px;
  padding-bottom: 10px;
}
.search-row {
  margin: 10px 0 0 10px;
}
.sub-label {
  float: left;
  vertical-align: middle;
  line-height: 32px;
}
.sub-content {
  margin-left: 80px;
}
.content-box {
  background-color: #fff;
  overflow: hidden;
}
.tab-header {
  display: flex;
  margin-bottom: 10px;
  li {
    &.active {
      color: #fdad00;
    }
  }
}
.header {
  display: flex;
  background-color: #f9f9f9;
  margin-bottom: 10px;
  &-item {
    padding: 10px 20px;
    margin: 0 5px;
    cursor: pointer;
    &.active {
      font-weight: 700;
      border-bottom: 3px #fdad00 solid;
    }
  }
}
</style>
